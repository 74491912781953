.icon_date{
    cursor: pointer;
    font-size: 2.5rem;
}
.text-1{
    font-size: 1.8rem;
}
.button_burger, .button_burger:hover, .button_burger:active, .button_burger:focus {
    outline: none !important;
    box-shadow: none !important;
    font-size: 1.6rem;
}
.btn-close, .btn-close:hover, .btn-close:active, .btn-close:focus{
    outline: none !important;
    box-shadow: none !important;
}
