.date-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;

    overflow: hidden;
    align-items: flex-start; /* Aligner les éléments au début de l'axe transversal */
    padding: 10px 0;

  }
  .date-bar button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
  }
  .date-bar button:focus {
    outline: none;
  }
  .date-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  @media (max-width: 445px) {
    .date-container {
      justify-content: center; /* Centre horizontalement */
      align-items: center; /* Centre verticalement */
    }
  }
  .date-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Aligner les éléments au début de l'axe transversal */
    flex: ;
  }
  .date-item {
    font-weight: 500;
    min-width: 300px;
    text-align:center;
    margin-top: 0; /* Assurez-vous qu'il n'y a pas de marge supérieure */
    /* border-bottom: 1px solid grey; */


  }
  .date-tasks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
  }
  .task-item {
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  
  
  .profile-container {
    display: flex;
    align-items: center;
  }
  
  .profile-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    margin-right: 10px; /* Ajustez la marge selon vos besoins */
  }
  
  .profile-icon svg {
    font-size: 16px; /* Ajustez la taille de l'icône selon vos besoins */
    color: #000; /* Ajustez la couleur de l'icône selon vos besoins */
  }
  
  .profile-text {
    font-size: 16px; /* Ajustez la taille du texte selon vos besoins */
  }